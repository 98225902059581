import { Link } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import logo from "../imagenes/logo.png";
import HamburgerMenu from "./hamburguermenu";

const Header = ({linksData}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  function getWindowDimensions() {
    const { innerWidth: width } = window;
    return { width };
  }

  const { width } = getWindowDimensions();

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  if (width > 900) {
  return (
    <header className="header">

      <div
        id="id8715737a8c6f3"
        a='{"t":"g7bs","v":"1.2","lang":"es","locs":[564],"ssot":"c","sics":"ms","cbkg":"rgb(69,90,100)","cfnt":"rgb(255,255,255)","cend":"rgba(0,0,0,0)","sfnt":"a","slfs":"22","eln":"bool"}'
      >
        Fuente de datos meteorológicos:{" "}
        <a href="https://sharpweather.com/es/tiempo_buenos_aires/30_días/">
          clima 30 días Buenos Aires
        </a>
      </div>
      <nav>
        <ol>
          <li className="logo">
            <Link to="/">
              <img src={logo} alt="Club Logo" />
            </Link>
          </li>
          <li>
            <Link to="/">Inicio</Link>
          </li>
          <li>
            <Link to="/Nosotros">Nosotros</Link>
          </li>
          <li>
            <Link to="/Plantel">Plantel</Link>
          </li>
          <li>
            <Link to="/Liga">Liga Areco</Link>
          </li>
          <li>
            <Link to="/Contacto">Contacto</Link>
          </li>
          <li>
            <Link to="/Sponsors">Sponsors</Link>
          </li>
          <li className="dropdown" onClick={toggleDropdown}>
            Links de interés
            {dropdownOpen && (
                <ul className="dropdown-menu">
                  {linksData.links.map((link, index) => (
                    <li key={index}>
                      <a href={link.url}>{link.nombre}</a>
                    </li>
                  ))}
              </ul>
            )}
          </li>
          <li></li>
        </ol>
      </nav>
    </header>
  );}else{
    return (
      <header className="header">
        <div
          id="id8715737a8c6f3"
          a='{"t":"g7bs","v":"1.2","lang":"es","locs":[564],"ssot":"c","sics":"ms","cbkg":"rgb(69,90,100)","cfnt":"rgb(255,255,255)","cend":"rgba(0,0,0,0)","sfnt":"a","slfs":"22","eln":"bool"}'
        >
          Fuente de datos meteorológicos:{" "}
          <a href="https://sharpweather.com/es/tiempo_buenos_aires/30_días/">
            clima 30 días Buenos Aires
          </a>
        </div>
        <nav>
          <ol>
            <li className="logo">
              <Link to="/">
                <img src={logo} alt="Club Logo" />
              </Link>
            </li>
            <li className="menu">
              <HamburgerMenu />
            </li>
          </ol>
        </nav>
      </header>
    );
  
  }
};

export default Header;
