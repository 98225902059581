import React from "react";
import { useParams } from "react-router-dom";
import style from "../css/detalleplayer.module.css";
import monumental from "../imagenes/masmonumental.jpg";

function Detalleplayer({ jugadores }) {
  const { jugadorData } = useParams();
  const decodedExerciseName = decodeURIComponent(jugadorData);
  
  let array = decodedExerciseName.split("-");

  const player = jugadores[0].jugadores.find((jugador) => jugador.nombre === array[0]);

  if (!player) {
    return <div>Jugador no encontrado</div>;
  }


  function edadActual(fechaString) {
    // Parsear la fecha desde la string
    let partesFecha = fechaString.split("-");
    let dia = parseInt(partesFecha[2], 10);
    let mes = parseInt(partesFecha[1], 10) - 1; // Los meses en JavaScript van de 0 a 11
    let anio = parseInt(partesFecha[0], 10);

    // Crear un objeto Date con la fecha
    let fecha = new Date(anio, mes, dia);

    // Obtener la fecha actual
    let fechaActual = new Date();

    // Calcular la diferencia en milisegundos
    let diferenciaEnMilisegundos = fechaActual - fecha;

    // Convertir la diferencia en años
    let aniosTranscurridos =
      diferenciaEnMilisegundos / (1000 * 60 * 60 * 24 * 365.25);

    // Redondear el resultado
    return Math.floor(aniosTranscurridos);
  }
  let age = edadActual(player.edad);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  const { width } = getWindowDimensions();

  if (width > 900) {
  return (
    <div className={`${style.player}`}>
              

      <div>
        <img className={`${style.monu}`} src={monumental} alt="" />
        <h1>{player.nombre}</h1> <h1>{player.apellido}</h1>
      </div>

      <section>
        
        <div>
          <img src={`${player.url_imagen}`} alt="" />
        </div>
        <div>
        <div className={`${style.data} `}>
          <ol>
            <label htmlFor="#">#</label>
            <h2>{player.numero}</h2>
          </ol>
          <ol>
            <label htmlFor="">Posición</label>
            <h2>{player.posicion}</h2>
          </ol>
        </div>
        <div className={`${style.data}`}>
          <ol>
            <label htmlFor="">Edad</label>
            <h2>{age} Años</h2>
          </ol>
          <ol>
            <label htmlFor="">Altura</label>
            <h2>{player.altura} mts</h2>
          </ol>
        </div>
        </div>
        
      </section>
      {/* <img className={`${style.bg}`} src={bg} alt="" /> */}
    </div>
  );
}else{
  return(
    <div className={`${style.player}`}>
              

      <div>
        <img className={`${style.monu}`} src={monumental} alt="" />
        <h1>{player.nombre}</h1> <h1>{player.apellido}</h1>
        <div>
          <img src={`${player.url_imagen}`} alt="" />
        </div>
      </div>

      <section>
        
        
        <div>
        <div className={`${style.data} `}>
          <ol>
            <label htmlFor="#">#</label>
            <h2>{player.numero}</h2>
          </ol>
          <ol>
            <label htmlFor="">Posición</label>
            <h2>{player.posicion}</h2>
          </ol>
        </div>
        <div className={`${style.data}`}>
          <ol>
            <label htmlFor="">Edad</label>
            <h2>{age} Años</h2>
          </ol>
          <ol>
            <label htmlFor="">Altura</label>
            <h2>{player.altura} mts</h2>
          </ol>
        </div>
        </div>
        
      </section>
      {/* <img className={`${style.bg}`} src={bg} alt="" /> */}
    </div>
  )
}
}

export default Detalleplayer;
