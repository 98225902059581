import React, { useEffect, useState } from 'react';
import Match from './match';
import style from '../css/liga.module.css';

const Fixture = () => {
  const [matches, setMatches] = useState([]);
  const [teams, setTeams] = useState({});

  const storedData = localStorage.getItem('liga');
  const ligaData = JSON.parse(storedData) || [];

  useEffect(() => {
    setMatches(ligaData.liga.matches);
    const teamsMap = ligaData.liga.teams.reduce((map, team) => {
      map[team.id] = team.name;
      return map;
    }, {});
    setTeams(teamsMap);
  }, []);

  const getMatchesForRound = (round) => {
    return matches.filter(match => match.round === round);
  };

  const instancias = ["octavos", "cuartos", "semifinal", "final"];


  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  const { width } = getWindowDimensions();

  if (width > 900) {

  return (
    <div className={`${style.fixture}`}>
      <div>
        {instancias.map(inst => (
          <div key={inst} className={`${style.round}`}>
            <h2 className="cornerLT cornerRB" key={inst}>{inst}</h2>
            <ol>

            {getMatchesForRound(inst).map(match => (
              <Match
                key={match.match}
                team1G={match.team1G}
                team2G={match.team2G}
                team1={teams[match.team1]}  // Buscando el nombre del equipo a partir de la ID
                team2={teams[match.team2]}  // Buscando el nombre del equipo a partir de la ID
                time={match.time}
                id={match.match}
              />
            ))}
            </ol>
            
          </div>
        ))}
      </div>
    </div>
  );
} else{
  return(
    <div className={`${style.fixture}`}>
      <div>
        <ol>
          {instancias.map(inst => (
            
            <div key={inst} className={`${style.round}`}>
              <h2 className="cornerLT cornerRB" >{inst}</h2>
            {getMatchesForRound(inst).map(match => (
              <Match
                key={match.match}
                team1G={match.team1G}
                team2G={match.team2G}
                team1={teams[match.team1]}  // Buscando el nombre del equipo a partir de la ID
                team2={teams[match.team2]}  // Buscando el nombre del equipo a partir de la ID
                time={match.time}
                id={match.match}
              />
            ))}
          </div>
            

          ))}
        </ol>
      </div>
    </div>
  )
}
};

export default Fixture;
