import React from "react";
import style from "../css/liga.module.css";

const Match = ({ team1, team2, time, id, team1G, team2G }) => {
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  const { width } = getWindowDimensions();

    return (
      <div className={`${style.match} cornerLT cornerRB`}>
        <ol>
          <div>{team1 || "?"}</div>
            <li>{team1G}</li>
            <li>{team2G}</li>
          <div>{team2 || "?"}</div>
        </ol>
      </div>
    );
  
};

export default Match;
