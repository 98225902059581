import React from "react";
import style from "../css/nosotros.module.css";
function Nosotros( {lorem, ad}) {
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  const { width } = getWindowDimensions();
  

  if (width > 900) {
    return (
      <div className="inicio">
        <div className="left-img">
          <img src={ad} alt="" />
        </div>
        <div>
          <div className={`${style.maincontent} main-content`}>
            <h2 className="cornerLT cornerRB">Sobre nosotros</h2>
            <p>{lorem}</p>
          </div>
        </div>
        <div className="right-img">
          <img src={ad} alt="" />
        </div>
      </div>
    );
  } else {
    return (
      <div>
          <div className={`${style.maincontent} main-content`}>
            <h2 className="cornerLT cornerRB">Sobre nosotros</h2>
            <p>{lorem}</p>
          </div>
        </div>
    )
  }
}

export default Nosotros;

