import React from "react";
import style from "../css/plantel.module.css";
import { Link } from "react-router-dom";

function Plantel({jugadores, ad}) {
  // jugadores = jugadores.jugadores;
  if(jugadores == null){
    jugadores = {0:{"jugadores":["test"]}};
  }
  ad = ad[0].url;
  return (
    <div className={`${style.Plantel}`}>
      <div className="left-img">
        <img src={ad} alt="" />
      </div>

      <main>
        {jugadores[0].jugadores.map((jugador, index) => (
          <Link key={index} className={`${style.player}`} to={`/Plantel/${encodeURIComponent(jugador.nombre)}-${encodeURIComponent(jugador.apellido)}`}>
            <img src={jugador.url_imagen} alt="" />

            <nav>
              <h3>
                {jugador.nombre} <br /> {jugador.apellido}
              </h3>

              <span>{jugador.numero}</span>
            </nav>
          </Link>
        ))}
      </main>

      <div className="right-img">
        <img src={ad} alt="" />
      </div>
    </div>
  );
}

export default Plantel;
