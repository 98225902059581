// src/components/HamburgerMenu.js
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../css/HamburgerMenu.modules.css"; // Asegúrate de crear un archivo CSS para estilos

const HamburgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="hamburger-menu">
      <button className="hamburger-button" onClick={toggleMenu}>
        ☰
      </button>
      <div className={`menu-content ${isOpen ? "open" : ""}`}>
        <button className="hamburger-button" onClick={toggleMenu}>
          ☰
        </button>
        <ol>

          <li>
            <Link to="/" onClick={toggleMenu}>Inicio</Link>
          </li>
          <li>
            <Link to="/Nosotros" onClick={toggleMenu}>Nosotros</Link>
          </li>
          <li>
            <Link to="/Plantel" onClick={toggleMenu}>Plantel</Link>
          </li>
          <li>
            <Link to="/Liga" onClick={toggleMenu}>Liga Areco</Link>
          </li>
          <li>
            <Link to="/Contacto" onClick={toggleMenu}>Contacto</Link>
          </li>
          <li>
            <Link to="/Sponsors" onClick={toggleMenu}>Sponsors</Link>
          </li>
        </ol>

      </div>
    </div>
  );
};

export default HamburgerMenu;
