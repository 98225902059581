import React, { useState } from 'react';
import styles from '../css/contacto.module.css';

function Contacto() {
  const [formData, setFormData] = useState({
    email: '',
    telefono: '',
    mensaje: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Datos enviados:', formData);
    sendEmail();
  };

  function sendEmail() {
    console.log('object')
    const email = formData.email;
    const message = formData.mensaje;
    const numero = formData.telefono;
    fetch(process.env.REACT_APP_MAIL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email: email, message:message, numero:numero })
    })
    .then(response => response.json())
    .then(data => {
        alert(data.message);
        console.log(data.message)
    })
    .catch((error) => {
        console.error('Error:', error);
    });
}

  return (
    <div className={styles.container}>
      <form className={styles.form} onSubmit={handleSubmit}>
        <h2 className={styles.title}>Contacto</h2>
        <div>
          <label className={styles.label}>Email:</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            className={styles.input}
          />
        </div>
        <div>
          <label className={styles.label}>Teléfono:</label>
          <input
            type="tel"
            name="telefono"
            value={formData.telefono}
            onChange={handleChange}
            required
            className={styles.input}
          />
        </div>
        <div>
          <label className={styles.label}>Mensaje:</label>
          <textarea
            name="mensaje"
            value={formData.mensaje}
            onChange={handleChange}
            required
            className={styles.textarea}
          />
        </div>
        <button type="submit" className={styles.button}>
          Enviar
        </button >
      </form>
    </div>
  );
}

export default Contacto;
