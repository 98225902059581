import React, { useState } from "react";
import styles from "../css/gestion.module.css";

function Autogestion({ liga, links, players, sponsors, nosotros }) {
  // Estados para los datos originales y los datos modificados
  const [league, setLeague] = useState(liga.liga);
  const [useLinks, setLinks] = useState(links.links);
  const [noticia, setNoticias] = useState(links.noticia);
  const [playerList, setPlayerList] = useState(players[0].jugadores);
  const [sponsorList, setSponsorList] = useState(sponsors.sponsors);
  const [adList, setAdList] = useState(sponsors.ads);
  const [us, setNosotros] = useState(nosotros);

  const handlePlayerChange = (index, event) => {
    const newPlayers = [...playerList];
    newPlayers[index][event.target.name] = event.target.value;
    setPlayerList(newPlayers);
  };

  const handlePlayerAdd = () => {
    const newPlayer = {
      nombre: "",
      apellido: "",
      posicion: "",
      numero: "",
      url_imagen: "",
      edad: "",
      altura: "",
    };
    setPlayerList([...playerList, newPlayer]);
  };

  const handlePlayerDelete = (index) => {
    const newPlayers = playerList.filter((_, i) => i !== index);
    setPlayerList(newPlayers);
  };

  const handleSponsorChange = (index, event) => {
    const newSponsors = [...sponsorList];
    newSponsors[index][event.target.name] = event.target.value;
    setSponsorList(newSponsors);
  };

  const handleSponsorAdd = () => {
    const newSponsor = {
      name: "",
      logo: "",
      website: "",
    };
    setSponsorList([...sponsorList, newSponsor]);
  };

  const handleSponsorDelete = (index) => {
    const newSponsors = sponsorList.filter((_, i) => i !== index);
    setSponsorList(newSponsors);
  };

  const handleTeamChange = (index, event) => {
    const newTeams = [...league.teams];
    newTeams[index][event.target.name] = event.target.value;
    setLeague({ ...league, teams: newTeams });
  };

  const handleLinkChange = (index, event) => {
    const newLinks = [...useLinks];
    newLinks[index][event.target.name] = event.target.value;
    setLinks(newLinks);
  };
  const handleNoticiaChange = (event) => {
    const newNews = [...noticia];
    newNews[0][event.target.name] = event.target.value;
    setNoticias(newNews);
  };

  const handleNosotrosChange = (event) => {
    setNosotros(event.target.value);
  };

  const handleMatchChange = (index, event) => {
    const newMatches = [...league.matches];
    newMatches[index][event.target.name] = event.target.value;
    setLeague({ ...league, matches: newMatches });
  };

  const handleMatchAdd = () => {
    const newMatch = {
      round: "",
      match: league.matches.length + 1,
      team1: "",
      team2: "",
      date: "",
      time: "",
      team1G: "",
      team2G: "",
    };
    setLeague({ ...league, matches: [...league.matches, newMatch] });
  };

  const handleMatchDelete = (index) => {
    const newMatches = league.matches.filter((_, i) => i !== index);
    setLeague({ ...league, matches: newMatches });
  };

  const handleTeamAdd = () => {
    const newTeam = {
      id: league.teams.length + 1, // Asegúrate de generar un ID único
      name: "",
    };
    setLeague({ ...league, teams: [...league.teams, newTeam] });
  };

  const handleTeamDelete = (index) => {
    const newTeams = league.teams.filter((_, i) => i !== index);
    setLeague({ ...league, teams: newTeams });
  };

  const handleLinkAdd = () => {
    const newLink = {
      nombre: "",
      url: "",
    };
    setLinks([...useLinks, newLink]);
  };

  const handleLinkDelete = (index) => {
    const newLinks = useLinks.filter((_, i) => i !== index);
    setLinks(newLinks);
  };

  // Manejo de cambios para anuncios
  const handleAdChange = (index, event) => {
    const newAds = [...adList];
    newAds[index][event.target.name] = event.target.value;
    setAdList(newAds);
  };

  const updateLeagueData = async (section) => {
    try {
      // Prepare the data to be sent based on the section
      const dataToSend = {};
      if (section === "teams" || section === "all") {
        dataToSend.teams = league.teams;
      }
      if (section === "matches" || section === "all") {
        dataToSend.matches = league.matches;
      }
      if (section === "links" || section === "all") {
        dataToSend.links = useLinks;
      }
      if (section === "players" || section === "all") {
        dataToSend.players = playerList;
      }
      if (section === "sponsors" || section === "all") {
        dataToSend.sponsors = sponsorList;
      }
      if (section === "ads" || section === "all") {
        dataToSend.ads = adList;
      }
      if (section === "noticia" || section === "all") {
        dataToSend.noticia = noticia;
      }
      if (section === "nosotros" || section === "all") {
        dataToSend.nosotros = us;
      }

      const response = await fetch(process.env.REACT_APP_UPDATE, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataToSend),
      });

      if (!response.ok) {
        throw new Error("Error en la actualización de datos");
      }
      const result = await response.json();
      alert("Datos actualizados correctamente:", result);
    } catch (error) {
      console.error("Error al actualizar los datos:", error);
    }
  };

  const handleUpdateTeams = () => {
    updateLeagueData("teams");
  };
  const handleNosotros = () => {
    updateLeagueData("nosotros");
  };

  const handleUpdateMatches = () => {
    updateLeagueData("matches");
  };

  const handleUpdateLinks = () => {
    updateLeagueData("links");
  };
  const handleUpdateNoticias = () => {
    updateLeagueData("noticia");
  };

  const handleUpdatePlayers = () => {
    updateLeagueData("players");
  };

  // Funciones para actualizar patrocinadores, anuncios o todo
  const handleUpdateSponsors = () => {
    updateLeagueData("sponsors");
  };

  const handleUpdateAds = () => {
    updateLeagueData("ads");
  };

  const handleUpdateAll = () => {
    updateLeagueData("all");
  };

  return (
    <div className={`${styles.autogestion_container}`}>
      <h1>Editar Liga</h1>
      <div className={`${styles.autogestion_section}`}>
        <h2>Noticias</h2>
        <div>
          <input
            type="text"
            name="titulo"
            value={noticia[0].titulo}
            placeholder="Titulo"
            onChange={(event) => handleNoticiaChange(event)}
          />
          <input
            type="text"
            name="url"
            placeholder="url"
            value={noticia[0].url}
            onChange={(event) => handleNoticiaChange(event)}
          />
          <input
            type="text"
            name="img"
            placeholder="imagen"
            value={noticia[0].img}
            onChange={(event) => handleNoticiaChange(event)}
          />
          <img src={noticia[0].img} alt={noticia[0].titulo} />
        </div>
        <button onClick={handleUpdateNoticias}>Actualizar Noticias</button>
      </div>

      <div className={`${styles.autogestion_section}`}>
        <h2>Equipos</h2>
        {league.teams.map((team, index) => (
          <div key={team.id}>
            <input
              type="text"
              name="name"
              value={team.name}
              onChange={(event) => handleTeamChange(index, event)}
            />
            <button
              className={`${styles.delete_button}`}
              onClick={() => handleTeamDelete(index)}
            >
              Eliminar Equipo
            </button>
          </div>
        ))}
        <button onClick={handleTeamAdd}>Agregar Equipo</button>
        <button onClick={handleUpdateTeams}>Actualizar Equipos</button>
      </div>

      <div className={`${styles.autogestion_section}`}>
        <h2>Jugadores</h2>
        {playerList.map((player, index) => (
          <div key={index} className={`${styles.player_container}`}>
            <input
              type="text"
              name="nombre"
              value={player.nombre}
              placeholder="Nombre"
              onChange={(event) => handlePlayerChange(index, event)}
            />
            <input
              type="text"
              name="apellido"
              value={player.apellido}
              placeholder="Apellido"
              onChange={(event) => handlePlayerChange(index, event)}
            />
            <input
              type="text"
              name="posicion"
              value={player.posicion}
              placeholder="Posición"
              onChange={(event) => handlePlayerChange(index, event)}
            />
            <input
              type="number"
              name="numero"
              value={player.numero}
              placeholder="Número"
              onChange={(event) => handlePlayerChange(index, event)}
            />
            <input
              type="text"
              name="url_imagen"
              value={player.url_imagen}
              placeholder="URL Imagen"
              onChange={(event) => handlePlayerChange(index, event)}
            />
            <input
              type="date"
              name="edad"
              value={player.edad}
              placeholder="Fecha de Nacimiento"
              onChange={(event) => handlePlayerChange(index, event)}
            />
            <input
              type="text"
              name="altura"
              value={player.altura}
              placeholder="Altura"
              onChange={(event) => handlePlayerChange(index, event)}
            />
            <img src={player.url_imagen} alt={player.nombre} />
            <button
              className={`${styles.delete_button}`}
              onClick={() => handlePlayerDelete(index)}
            >
              Eliminar a {player.nombre} {player.apellido}
            </button>
          </div>
        ))}
        <button onClick={handlePlayerAdd}>Agregar Jugador</button>
        <button onClick={handleUpdatePlayers}>Actualizar Jugadores</button>
      </div>

      {/* Sección de Patrocinadores */}
      <div className={`${styles.autogestion_section}`}>
        <h2>Patrocinadores</h2>
        {sponsorList.map((sponsor, index) => (
          <div key={index}>
            <input
              type="text"
              name="name"
              value={sponsor.name}
              placeholder="Nombre"
              onChange={(event) => handleSponsorChange(index, event)}
            />
            <input
              type="text"
              name="logo"
              value={sponsor.logo}
              placeholder="URL Logo"
              onChange={(event) => handleSponsorChange(index, event)}
            />
            <input
              type="text"
              name="website"
              value={sponsor.website}
              placeholder="Sitio Web"
              onChange={(event) => handleSponsorChange(index, event)}
            />
            <img src={sponsor.logo} alt={sponsor.name} />
            <button
              className={`${styles.delete_button}`}
              onClick={() => handleSponsorDelete(index)}
            >
              Eliminar Patrocinador
            </button>
          </div>
        ))}
        <button onClick={handleSponsorAdd}>Agregar Patrocinador</button>
        <button onClick={handleUpdateSponsors}>
          Actualizar Patrocinadores
        </button>
      </div>

      {/* Sección de Anuncios */}
      <div className={`${styles.autogestion_section}`}>
        <h2>Imagenes</h2>
        {adList.map((ad, index) => (
          <div key={index}>
            <input
              type="text"
              name="nombre"
              value={ad.nombre}
              placeholder="Nombre"
              onChange={(event) => handleAdChange(index, event)}
            />
            <input
              type="text"
              name="url"
              value={ad.url}
              placeholder="URL"
              onChange={(event) => handleAdChange(index, event)}
            />
            <img src={ad.url} alt={ad.nombre} />
          </div>
        ))}
        <button onClick={handleUpdateAds}>Actualizar Imagenes</button>
      </div>

      <div className={`${styles.autogestion_section}`}>
        <h2>Links de Interés</h2>
        {useLinks.map((link, index) => (
          <div key={index}>
            <input
              type="text"
              name="nombre"
              value={link.nombre}
              onChange={(event) => handleLinkChange(index, event)}
            />
            <input
              type="url"
              name="url"
              value={link.url}
              onChange={(event) => handleLinkChange(index, event)}
            />
            <button
              className={`${styles.delete_button}`}
              onClick={() => handleLinkDelete(index)}
            >
              Eliminar Link
            </button>
          </div>
        ))}
        <button onClick={handleLinkAdd}>Agregar Link</button>
        <button onClick={handleUpdateLinks}>Actualizar Links</button>
      </div>

      <div className={`${styles.autogestion_section}`}>
        <h2>Partidos</h2>
        {league.matches.map((match, index) => (
          <div key={index}>
            <h3>Partido {match.match}</h3>
            <select
              name="round"
              value={match.round}
              onChange={(event) => handleMatchChange(index, event)}
            >
              <option value="">Seleccionar ronda</option>
              <option value="octavos">Octavos</option>
              <option value="cuartos">Cuartos</option>
              <option value="semifinal">Semifinal</option>
              <option value="final">Final</option>
            </select>
            <select
              name="team1"
              value={match.team1}
              onChange={(event) => handleMatchChange(index, event)}
            >
              <option value="">Seleccionar equipo 1</option>
              {league.teams.map((team) => (
                <option key={team.id} value={team.id}>
                  {team.name}
                </option>
              ))}
            </select>
            <select
              name="team2"
              value={match.team2}
              onChange={(event) => handleMatchChange(index, event)}
            >
              <option value="">Seleccionar equipo 2</option>
              {league.teams.map((team) => (
                <option key={team.id} value={team.id}>
                  {team.name}
                </option>
              ))}
            </select>
            <input
              type="date"
              name="date"
              value={match.date}
              onChange={(event) => handleMatchChange(index, event)}
            />
            <input
              type="time"
              name="time"
              value={match.time}
              onChange={(event) => handleMatchChange(index, event)}
            />
            <input
              type="number"
              name="team1G"
              value={match.team1G}
              onChange={(event) => handleMatchChange(index, event)}
            />
            <input
              type="number"
              name="team2G"
              value={match.team2G}
              onChange={(event) => handleMatchChange(index, event)}
            />
            <button
              className={`${styles.delete_button}`}
              onClick={() => handleMatchDelete(index)}
            >
              Eliminar Partido
            </button>
          </div>
        ))}
        <button onClick={handleMatchAdd}>Agregar Partido</button>
        <button onClick={handleUpdateMatches}>Actualizar Partidos</button>
      </div>

      <div className={`${styles.autogestion_section}`}>
        <h2>Nosotros</h2>
        <div>
          <textarea
            rows={10}
            readOnly={false}
            disabled={false}
            value={us}
            onChange={(event) => handleNosotrosChange(event)}
          />
        </div>
        <button onClick={handleNosotros}>Actualizar Texto</button>
      </div>

      <button onClick={handleUpdateAll}>Actualizar Todo</button>
    </div>
  );
}

export default Autogestion;
