import { Routes, Route, BrowserRouter } from "react-router-dom";
import { useState, useEffect } from "react";
import Header from "./components/Header";
import Inicio from "./components/inicio";
import Plantel from "./components/Plantel";
import Ligaareco from "./components/ligaareco";
import Contacto from "./components/Contacto";
import Nosotros from "./components/Nosotros";
import Sponsors from "./components/Sponsors";
import Detalleplayer from "./components/Detalleplayer";
import Autogestion from "./components/autogestion";
import "./App.css";

const App = () => {
  const [lorem, setLorem] = useState();
  const [links, setLinks] = useState();
  const [liga, setLiga] = useState();
  const [players, setPlayers] = useState();
  const [sponsors, setSponsors] = useState();
  const [isLoading, setIsLoading] = useState(true); // Estado para manejar la carga

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    localStorage.setItem("liga", JSON.stringify(liga))
  }, [liga]);

  const fetchData = async () => {
    try {
      await Promise.all([fetchLorem(), fetchLinks(), fetchLiga(), fetchPlayers(),fetchSponsors()]);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchLorem = () => {
    return fetch(process.env.REACT_APP_LOREM, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setLorem(data);
      });
  };


  const fetchLinks = () => {
    return fetch(process.env.REACT_APP_LINKS, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setLinks(data.links);
      });
  };

  const fetchLiga = () => {
    return fetch(process.env.REACT_APP_LIGA, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setLiga(data);
      });
  };

  const fetchSponsors = () => {
    return fetch(process.env.REACT_APP_PATROCINADOR, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setSponsors(data);
      });
  };

  const fetchPlayers = () => {
    return fetch(process.env.REACT_APP_PLAYERS, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setPlayers(data);
      });
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://static1.sharpweather.com/widgetjs/?id=id8715737a8c6f3";
    script.async = true;
    document.body.appendChild(script);
  
    return () => {
      document.body.removeChild(script);
    }
  }, []);

  if (isLoading) {
    return <div></div>; 
  }
  return (
    <div className="main-component">
      <BrowserRouter>
        <Header linksData={links} />
        <Routes>
          <Route path="/" element={<Inicio ad={sponsors.ads} noticia={links.noticia[0]}/>} />
          <Route path="/autogestion" element={<Autogestion liga={liga} links={links} players={players} sponsors={sponsors} nosotros={lorem}/>} />
          <Route path="/Header" element={<Header linksData={links} />} />
          <Route path="/Plantel" element={<Plantel jugadores={players} ad={sponsors.ads} />} />
          <Route path="/Liga" element={<Ligaareco ligaData={liga} />} />
          <Route path="/Contacto" element={<Contacto />} />
          <Route path="/Nosotros" element={<Nosotros lorem={lorem} ad={sponsors.ads[0].url} />} />
          <Route path="/Sponsors" element={<Sponsors sponsorsData={sponsors}/>} />
          <Route path="/Plantel/:jugadorData" element={<Detalleplayer jugadores={players} />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
