import React from 'react';
import styles from '../css/sponsors.module.css';



function Sponsors({sponsorsData}) {
  sponsorsData = sponsorsData.sponsors;
  return (
    <div className={styles.container}>
      <h1 className={`${styles.title} cornerLT cornerRB`}>Nuestros Patrocinadores</h1>
      <div className={styles.sponsorsGrid}>
        {sponsorsData.map((sponsor, index) => (
          <a 
            key={index} 
            href={sponsor.website} 
            target="_blank" 
            rel="noopener noreferrer"
            className={styles.sponsorCard}
          >
            <img src={sponsor.logo} alt={sponsor.name} className={styles.logo} />
            <p className={styles.name}>{sponsor.name}</p>
          </a>
        ))}
      </div>
    </div>
  );
}

export default Sponsors;
