import React, { useState } from "react";
import style from "../css/inicio.module.css";

const Inicio = ({ad, noticia}) => {
  const [stadium, setStadium] = useState(false);
  const predio = ad[2].url;
  const mainImage = noticia.img;
  const newsUrl = noticia.url;
  const title = noticia.titulo;
console.log(noticia)
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  const { width } = getWindowDimensions();
 
  if (width > 900) {
    ad = ad[0].url
    return (
      <div className={`${style.inicio} inicio`}>
        <div className={`${style.left_img}`}>
          <img src={ad} alt="" />
        </div>

        <section>
        <div className={`${style.maincontent} main-content`}>
          <div className={`${style.maincontentholder} main-content-holder`}>
            <h2 className="cornerLT cornerRB">
              {title}
            </h2>
            <img src={mainImage} alt="Main Event" />
            <button> <a href={newsUrl}>Ver Resumen del partido</a> </button>
          </div>

          <div className={`${style.links}`}>
            <div>
              <h2 className="cornerLT cornerRB">Nuestro Predio</h2>
              <img
                src={predio}
                alt="Stadium"
                onClick={() => setStadium(true)}
              />
            </div>
          </div>
        </div>
        {stadium ? (
          <div className={`${style.estadiofs}`} onClick={() => setStadium(false)}>
            <button>
              <img src={predio} alt="Predio" />
            </button>
          </div>
        ) : null}
        </section>
        
        <div className={`${style.right_img}`}>
          <img src={ad} alt="" />
        </div>
      </div>
    );
  } else {
    ad = ad[1].url
    return (
      <div className={`${style.inicio} inicio`}>
        <div className={`${style.maincontent} main-content`}>
          <div className={`${style.maincontentholder} main-content-holder`}>
            <h2 className="cornerLT cornerRB">
              River le ganó 4 a 0 a Rivadavia
            </h2>
            <img src={mainImage} alt="Main Event" />
            <button>Ver Resumen del partido</button>
          </div>

          <div className={`${style.links}`}>
            <div>
              <h2 className="cornerLT cornerRB">Nuestro Predio</h2>
              <img
                src={predio}
                alt="Stadium"
                onClick={() => setStadium(true)}
              />
            </div>
            <div className="ad">
              <img src={ad} alt="" />
            </div>
          </div>
        </div>
        {stadium ? (
          <div className={`${style.estadiofs}`} onClick={() => setStadium(false)}>
            <button>
              <img src={predio} alt="Predio" />
            </button>
          </div>
        ) : null}
      </div>
    );
  }
};

export default Inicio;
